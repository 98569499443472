import {
  UndefinedInitialDataOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { client } from "../utils/axios-utils";
import { PaymentUserOptionsResponse } from "./getUserPaymentOptions";

export interface UserData {
  data: {
    name: string;
    phone_number?: string;
    email?: string;
    id?: string;
    cards: PaymentUserOptionsResponse["data"];
  };
}

export const getUser = async () => {
  const response = await client.get<UserData>("/user/me");

  return response.data;
};

export const GetUserKey = ["user", "me"];

export const getUserProfileOptions = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<UserData, unknown, UserData>,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  queryOptions({
    queryKey: GetUserKey,
    queryFn: getUser,
    select: (data) => {
      return data.data;
    },
    retry: false,
    gcTime: Infinity,
    ...options,
  });

export const useGetProfile = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<UserData, unknown, UserData>,
      "queryKey" | "queryFn" | "select"
    >
  >,
) => useQuery({ ...getUserProfileOptions(options), staleTime: Infinity });
