import { getCSSVariable } from "./getCSSVariable";

export const calculatePosition = (
  targetPosition: DOMRect | "center" = "center",
  container?: HTMLElement | null,
): void => {
  if (!container) return;

  container.style.position = "";

  if (targetPosition === "center") return; // from now we don't need to calculate center as we are getting it by flex-box of the iframe's body

  const offset = 10;
  const computedStyles = getComputedStyle(container);
  // const containerPosition = container.getBoundingClientRect();
  const mobileBreakPoint = Number(
    getCSSVariable("--mobile-break-point", "576").replace(/\D/gi, ""),
  );

  if (window.innerWidth >= 320 && window.innerWidth < mobileBreakPoint) {
    container.style.top = "initial";

    container.style.right = "0px";
    container.style.bottom = "0px";
    container.style.left = "0px";
    container.style.margin = "auto";
    return;
  }

  /* if (targetPosition === 'center') {
    container.style.top =
      (window.innerHeight - containerPosition.height) / 2 + 'px';
    container.style.right =
      (window.innerWidth - containerPosition.width) / 2 + 'px';
    container.style.bottom = 'initial';
    container.style.left = 'initial';
    return;
  } */

  if (
    targetPosition.y + targetPosition.height + container.clientHeight >
      window.innerHeight &&
    targetPosition.y > container.clientHeight
  ) {
    container.style.bottom =
      window.innerHeight -
      targetPosition.y +
      offset -
      Number(computedStyles.paddingRight.replace(/[a-zA-Z]/gi, "")) +
      "px";
    container.style.top = "initial";
  } else {
    container.style.top =
      targetPosition.top +
      targetPosition.height +
      offset -
      Number(computedStyles.paddingTop.replace(/[a-zA-Z]/gi, "")) +
      "px";
    container.style.bottom = "initial";
  }

  if (window.innerHeight - container.clientHeight < 100) {
    container.style.bottom = 0 + "px";
    container.style.top = "0px";
  }

  if (
    targetPosition.x + targetPosition.width + container.clientWidth >
    window.innerWidth
  ) {
    container.style.right =
      window.innerWidth -
      targetPosition.right -
      Number(computedStyles.paddingRight.replace(/[a-zA-Z]/gi, "")) +
      "px";
    container.style.left = "initial";

    if (
      container.getBoundingClientRect().x -
        Number(computedStyles.paddingRight.replace(/[a-zA-Z]/gi, "")) <
      0
    ) {
      container.style.left =
        Number(computedStyles.paddingRight.replace(/[a-zA-Z]/gi, "")) + "px";
      container.style.right = "initial";
    }
  } else {
    container.style.left =
      targetPosition.left -
      Number(computedStyles.paddingLeft.replace(/[a-zA-Z]/gi, "")) +
      "px";
    container.style.right = "initial";
  }

  container.style.position = "fixed";
};
