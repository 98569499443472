"use client";
import DataDog from "@components/DataDog";
import { AuthProvider } from "@context/auth";
import { ConfigProvider } from "@context/config";
import { NextUIProvider } from "@nextui-org/react";
import {
  QueryClient,
  QueryClientProvider,
  isServer,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRouter } from "next/navigation";

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

function Providers({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const queryClient = getQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <DataDog>
        <AuthProvider>
          <ConfigProvider>
            <NextUIProvider navigate={router.push}>{children}</NextUIProvider>
          </ConfigProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </DataDog>
    </QueryClientProvider>
  );
}

export default Providers;
