import { datadogRum } from "@datadog/browser-rum";
import { jam } from "@jam.dev/sdk";
import { GetEnvKeys, getEnv } from "@queries/getEnv";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import npmPackage from "../../package.json";

const DataDogRum: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    jam.metadata(() => {
      return {
        timeSincePageLoad: performance.now(),
      };
    });

    getEnv().then((res) => {
      queryClient.setQueryData(GetEnvKeys, res);

      if (process.env.NODE_ENV !== "development") {
        console.log(`
Name: ${npmPackage.name}
Current version: ${process.env.NEXT_PUBLIC_VERSION}
Env: ${res.data.data.data.environment}
`);

        datadogRum.init({
          allowedTracingUrls: [
            "http://localhost:3000",
            "https://checkout.staging.olyn.com",
            "https://checkout.demo.olyn.com",
            "https://checkout.olyn.com",
            "https://checkout-api.staging.olyn.com",
            "https://checkout-api.demo.olyn.com",
            "https://checkout-api.olyn.com",
          ],
          applicationId: "a2e8c9e7-b092-4e0d-b8ea-62c6be003fed",
          clientToken: "pub5889e9c9bed3c5d62fd461bd6a21d072",
          site: "datadoghq.com",
          service: npmPackage.name,
          env: res.data.data.data.environment ?? "localhost",
          version: process.env.NEXT_PUBLIC_VERSION! ?? "0.0.0",
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          storeContextsAcrossPages: true,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "allow",
          trackingConsent: "granted",
          trackSessionAcrossSubdomains: true,
        });

        datadogRum.startSessionReplayRecording();
      }
    });
  }, [queryClient]);

  return <>{children}</>;
};

export default DataDogRum;
