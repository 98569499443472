import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setScreen] = useState<{ width: number; height: number }>();

  useEffect(() => {
    const handleResize = () => {
      setScreen({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreen]);

  return windowSize;
};

export default useWindowSize;
