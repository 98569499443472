import {
  UndefinedInitialDataOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";
import isBrave from "@utils/isBrave";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "sonner";

export interface EnvironmentResponse {
  data: {
    data: {
      environment: string;
      version: string;
      user_country: string;
      user_ip: string;
    };
  };
}

export const getEnv = async () => {
  try {
    const response = await client.get<EnvironmentResponse>("env");

    return response;
  } catch (e: unknown) {
    toast.warning(
      `We detect that your browser is blocking some of ours services. For better experience please disable it or use another browser.${
        isBrave() ? " Using Brave? Turn off the shield" : ""
      }`,
      {
        duration: 1000 * 60,
        id: "error-on-api",
      },
    );

    throw e as AxiosError<DefaultErrorResponse>;
  }
};

export const GetEnvKeys = ["env"];

export const getEnvOptions = (
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        AxiosResponse<EnvironmentResponse, unknown>,
        AxiosError<DefaultErrorResponse>,
        EnvironmentResponse
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  queryOptions({
    queryFn: getEnv,
    queryKey: GetEnvKeys,
    staleTime: 1000 * 60 * 60,
    select: (data) => data.data.data.data,
    gcTime: Infinity,
    refetchOnReconnect: true,
    ...options,
  });

export const useGetEnv = () => useQuery(getEnvOptions());
