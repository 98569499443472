const isBrave = () => {
  if ("brave" in window.navigator) {
    //@ts-expect-error
    if (window.navigator.brave?.isBrave.name == "isBrave") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default isBrave;
