import "./global";

/// <reference path="./global.ts" />

export function messageListener(
  callback: (event: HostIFrameMessageEvent) => void,
  target?: Window,
  options?: boolean | AddEventListenerOptions | undefined,
): () => void {
  if (typeof window === "undefined") return () => {};

  if (!target) target = window;

  const listener = (event: HostIFrameMessageEvent) => {
    callback(event);
  };

  target.addEventListener("message", listener, options);

  return () => {
    target.removeEventListener("message", listener, options);
  };
}

/**
 * A wrapper of postMessage to support custom data type used in OlynCheckout
 *
 *
 */
export function customPostMessage(
  data: HostIFrameMessage,
  targetOrigin?: string,
  target?: Window,
  transfer?: Transferable[],
): void;
export function customPostMessage(
  data: HostIFrameMessage,
  targetOrigin?: WindowPostMessageOptions,
  target?: Window,
  transfer?: Transferable[],
): void;
export function customPostMessage(
  data: HostIFrameMessage,
  targetOrigin?: string | WindowPostMessageOptions,
  target?: Window,
  transfer?: Transferable[],
): void {
  if (typeof window === "undefined") return;

  if (!target) target = window;

  if (typeof targetOrigin === "string") {
    target.postMessage(data, targetOrigin, transfer);
  } else {
    target.postMessage(data, targetOrigin);
  }
}
