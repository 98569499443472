interface Output<T> {
  onClick: React.MouseEventHandler<T>;
}

function useRageClick<T extends HTMLElement>(
  callback: () => void,
  options: {
    rageThreshold: number;
  } = {
    rageThreshold: 6,
  },
): Output<T> {
  let lastClick = 0;
  let secondLastClick = 0;
  let totalClicks = 0;

  const clickCounter = () => {
    totalClicks++;
    if (totalClicks > options.rageThreshold) {
      totalClicks = 0;
    }
    console.log(totalClicks);
  };

  const resetAll = () => {
    lastClick = 0;
    secondLastClick = 0;
    totalClicks = 0;
  };

  const rageClick = (event: React.MouseEvent<T>) => {
    const now = Math.floor(Date.now());

    if (
      now - lastClick < 500 &&
      now - secondLastClick &&
      totalClicks >= options.rageThreshold
    ) {
      console.log("rage click on element");

      callback();

      resetAll();
    }
    secondLastClick = lastClick;
    lastClick = now;

    console.log(event.type + " " + now);
    console.log((now - secondLastClick) / 1000);
  };

  const onClick = (event: React.MouseEvent<T>) => {
    clickCounter();
    rageClick(event);
  };

  return {
    onClick,
  };
}

export default useRageClick;
