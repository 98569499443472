export const getCSSVariable = (
  variableName: string,
  fallback: string = "",
): string => {
  if (typeof window === "undefined") return fallback;

  const root = document.querySelector(":root");
  let value = "";

  if (root) {
    const cs = getComputedStyle(root);

    value = cs.getPropertyValue(variableName);
  }

  if (value === "") {
    return fallback;
  }

  return value;
};
