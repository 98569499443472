"use client";
import * as React from "react";

type ConfigContext = ConfigMessage["payload"] & {
  setConfig: (config: Partial<ConfigMessage["payload"]>) => void;
};

export const ConfigContext = React.createContext<ConfigContext | null>(null);

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = React.useState<Partial<ConfigMessage["payload"]>>(
    {},
  );

  return (
    <ConfigContext.Provider value={{ ...state, setConfig: setState }}>
      {children}
    </ConfigContext.Provider>
  );
}
