import { customPostMessage } from "@global/messaging";
import { deleteItem } from "./localStorage";

export const userLogout = () => {
  deleteItem("access_token");
  deleteItem("refresh_token");
  deleteItem("user_id");
  deleteItem("federated_access_token");
  deleteItem("phone_number");
  deleteItem("email");
  deleteItem("name");
  deleteItem("name_id");
  deleteItem("persona_id");

  customPostMessage(
    {
      type: "logout",
    },
    "*",
    typeof window !== "undefined" ? window.parent : undefined,
  );
};
